import React, {useEffect, useMemo, useState} from 'react';
import './Travel.css';
import {Link} from "react-router-dom";
import Loader from "../../UI/Loader/Loader";
import PostFilter from "../../components/PostFilter/PostFilter";
import volgaCaspiy from "../../images/volga-caspiy/caspiy_01.jpg";
import piter from "../../images/piter/hermitage_02.jpg";
import bulgaromania from "../../images/bulgaromania/trans_f_03.jpg";
import portugal from "../../images/portugal/032.jpg";
import crimea from "../../images/crimea/crimea_026.jpg";
import tyumen from "../../images/tyumen/tyumen_03.jpg";
import deneb from "../../images/deneb/038_magyar.jpg";
import transcaucasia from "../../images/transcaucasia_2016/logo_trip.png";
import kurgan from "../../images/kurgan/krasin.jpg";
import bigArmenia from "../../images/bigArmenia/kavkaz.jpg";
import zakavkaz from "../../images/zakavkaz/road_to_sunik.jpg";
import sevkaveksp from "../../images/sevkaveksp/sevkaveksp_031.JPG";
import elbrus2021 from "../../images/elbrus2021/elbrus2021_21.jpg";
import averkina from "../../images/averkina/006.jpg";
import goldenRing from "../../images/goldenRing/cover.jpg";
import ReverseBtn from "../../UI/ReverseBtn/ReverseBtn";
import armenia2024 from "../../images/armenia2024/armenia2024_cover.jpg";


const Travel = () => {
    const [travelState, setTravelState] = useState(
        [
            {id: 16, title: "Армения 2024. Южный маршрут.", img: armenia2024, path: "/armenia2024"},
            {id: 15, title: "Золотое кольцо", img: goldenRing, path: "/goldenRing"},
            {id: 14, title: "Аверкина яма", img: averkina, path: "/averkina"},
            {id: 13, title: "Приэльбрусье 2021", img: elbrus2021, path: "/elbrus2021"},
            {id: 12, title: "СевКавЭксп", img: sevkaveksp, path: "/sevkaveksp"},
            {id: 11, title: "Волга - Каспий. Июль 2020 г.", img: volgaCaspiy, path: "/volga-caspiy"},
            {id: 10, title: "Питер. Февраль 2020.", img: piter, path: "/piter2020"},
            {id: 9, title: "Болгария, Румыния. Сентябрь 2018.", img: bulgaromania, path: "/bulgaromania"},
            {id: 8, title: "Португалия. Март 2018.", img: portugal, path: "/portugal"},
            {id: 7, title: "Крым 2017", img: crimea, path: "/crimea"},
            {id: 6, title: "Тюмень 2017", img: tyumen, path: "/tyumen"},
            {id: 5, title: "Дунайский тур 2017", img: deneb, path: "/deneb"},
            {id: 4, title: "Закавказский экспресс 2016", img: transcaucasia, path: "/transcaucasia"},
            {id: 3, title: "Дорогой церквей. Курганская область.", img: kurgan, path: "/kurgan"},
            {id: 2, title: "Моё большое армянское путешествие 2015", img: bigArmenia, path: "/bigArmenia"},
            {id: 1, title: "Закавказская поездка 2014", img: zakavkaz, path: "/zakavkaz"},
        ]
    )

    const [isImgLoading, setIsImgLoading] = useState(true)

    const handleImgLoaded = () => {
        setIsImgLoading(false)
    }

    const [filterQuery, setFilterQuery] = useState('')

    const searchedTravels = useMemo(() => {
        if (filterQuery) {
            return [...travelState].filter(item => item.title.toLowerCase().includes(filterQuery.toLowerCase()))
        }
        return travelState
    }, [travelState, filterQuery])

    const handleReverse = () => {
        setTravelState([...travelState].reverse())
    }

    return (
            <div className="container travel__travelCon">
                <div className="row">
                    <PostFilter filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
                    <ReverseBtn
                        onClick={handleReverse}
                    />
                </div>

                <div className="row gx-2 travel__travelRow">
                    {
                        searchedTravels.map(item =>
                                <div className="col-md-4 travel__travelCardWrapper">
                                    <div className="travel__travelCard">
                                        <div className="travel__cardImgDiv">
                                            <img
                                                src={item.img}
                                                alt={item.title}
                                                className="img-fluid"
                                                onLoad={handleImgLoaded}
                                            />
                                        </div>
                                        <div className="travel__travelTitleDiv">
                                            <Link
                                                to={item.path}
                                                className="travel__link"
                                            >
                                                {item.title}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                        )
                    }
                </div>
                {
                    isImgLoading && <Loader/>
                }
            </div>
    );
};

export default Travel;